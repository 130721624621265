import React from "react";
import PdfViewer from "../pdf-viewer/PdfViewer";

// Dynamically generate an array of URLs for the pitch deck
const pitchDeckFiles = Array.from({ length: 22 }, (_, i) =>
  `/pitchdeck/Versus-PitchDeck-${String(i + 1).padStart(2, "0")}.png`
);

const PitchDeck = () => {
  return (
    <div style={{ backgroundColor: "#000", height: "100vh" }}>
      <PdfViewer fileArray={pitchDeckFiles} /> {/* Pass the file array */}
    </div>
  );
};

export default PitchDeck;
