import React from "react";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { VersusWebsiteFor } from "./screens/VersusWebsiteFor";
import { PitchDeck } from "./screens/PitchDeck";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<VersusWebsiteFor />} />
      <Route path="/pitchdeck" element={<PitchDeck />} />
    </Routes>
  </Router>
);
