import React, {useState, useEffect, useRef } from "react";
import "./mobile.css";

export const Mobile = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  
  // Function to scroll to the bottom of the page, ensuring the bottom of the viewport aligns with the bottom of the page content
  const scrollToBottom = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    
    window.scrollTo({
      top: scrollHeight - clientHeight, // Scroll to the bottom but stop where the viewport fits perfectly
      behavior: 'smooth',
    });
  };

  function scrollToSection(id) {
    const target = document.getElementById(id);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    } else {
      console.error(`Element with id "${id}" not found.`);
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="mobile">
      <div className="div">
        <div className="overlap">
          <img className="mobile-contacts" alt="Mobile contacts" src="/img/mobile-contacts-section.png" />
          <img className="mobile-targets" alt="Mobile targets" src="/img/mobile-contacts-section.png" />
        </div>
        <div className="overlap-group">
          <div className="mobile-contacts-2">
            <div className="text-wrapper">© 2024 Versus</div>
            <img className="vector" alt="Vector" src="/img/vector-87.svg" />
            <div className="text-wrapper-2">together@versusfundraising.com</div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <img className="img" alt="Mobile targets" src="/img/mobile-targets-background.png" />
              <div className="mobile-targets-2">
                <p className="let-s-not-just-fund">
                  Let’s not just fund the future - let’s change it. <br />
                  Together
                </p>
                <p className="we-seek-purpose">
                  We seek purpose-driven investors who are not just capital providers but active visionaries. You
                  understand the crucial role you play in shaping our collective future, committed to financing
                  unstoppable people with aspirations for a better world—for all of us and our loved ones .<br /> <br />
                  You recognize the need for a fundamental shift in how we create and engage with technology. We’re
                  looking for you—not just as an investor, but as a user who knows, deep down, that the internet can be
                  a different, vibrant space. You believe we can build a brighter future not as isolated individuals,
                  but as a united humanity. <br />
                  <br />
                  If you’re only after quick profits, prefer to maintain the status quo or lack of boldness to envision
                  and work on something drastically new , then this isn’t for you. But if you’re ready to champion
                  meaningful change, we invite you to join us
                </p>
                <p className="p">Who we are looking for</p>
              </div>
            </div>
          </div>
          <img id="documents" className="mobile-document" alt="Mobile document" src="/img/mobile-document-section.svg" />
        </div>
        <div className="mobile-documents">
          <div className="mobile-documents-2">
            <img className="mobile-whitepaper" alt="Mobile whitepaper" src="/img/mobile-whitepaper.png" onClick={() => window.open('/Versus-Whitepaper.pdf', '_blank')}/>
            <img className="mobile-pitchdeck" alt="Mobile pitchdeck" src="/img/mobile-pitchdeck.png" onClick={() => window.location.href = '/pitchdeck'}/>
          </div>
          <div className="mobile-documents-3">
            <p className="these-documents">
              These&nbsp;&nbsp;documents identifies the current internet fundamental problems, outlines the intellectual
              framework that supports our work and highlights the research and concepts that set Versus apart from
              conventional product
            </p>
            <div className="text-wrapper-3">Explore our vision</div>
          </div>
        </div>
        <div className="mobile-about">
          <div className="mobile-about-wrapper" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/img-mobile/mobile-about-4-background.png`}}>
            <div className="mobile-about-2">
              <p className="text-wrapper-4">
                “We are here to reclaim technology, not as as a tool of control, but as a tool of freedom”
              </p>
              <p className="we-spent-a-long-time">
                We spent a long time considering how to build a product that truly meets people&#39;s unique needs. We
                debated adding new features, better interfaces and conditions, but realized we were asking the wrong
                questions; we understood we couldn’t achieve new results by doing the same old things. What was needed
                wasn’t just another product, but a fundamentally new way of creating them
              </p>
              <div className="text-wrapper-5">Our realization</div>
            </div>
          </div>
        </div>
        <div className="mobile-about-3">
          <p className="we-believe-that-the">
            We believe that the problem isn’t technology itself, but the values and vision driving it. Change can&#39;t
            come from simply tweaking the surface (tech); we must go deeper, to the core of how and why we create
          </p>
          <img className="mobile-sunset" alt="Mobile sunset" src="/img/mobile-sunset.png" />
          <div className="text-wrapper-6">A fundamental belief</div>
        </div>
        <img className="mobile-about-4" alt="Mobile about" src="/img/mobile-about-2-section.svg" />
        <div className="mobile-about-5">
          <p className="text-wrapper-7">Where are we all going?</p>
          <p className="look-around-and-ask">
            Look around and ask yourself: <br />
            Do you have real power over your digital world? Is this the best version of the internet we can create? Or
            has it become something else—a centralized, standardised system built to control and exploit rather than
            empower? A place where your own unique identity, needs, and voice are secondary to someone else’s interests?
          </p>
        </div>
        <img id="targets" className="mobile-targets-section" alt="Mobile about" src="/img/mobile-about-1-section.svg" />
        <img id="about" className="mobile-about-6" alt="Mobile about" src="/img/mobile-about-1-section.svg" />
        <div className="mobile-about-7">
          <div className="about-intro">
            <div className="about-intro-text-wrapper" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/img-mobile/rectangle-75.png`}}>
              <div className="about-intro-text">
                <div className="text-wrapper-8">Dear user,</div>
                <p className="text-wrapper-9">
                  Yes, we know; If you are reading this, you are likely an investor—but more importantly, you are a user
                  of this internet. We want to speak to you not just as a funder of the future, but as someone who
                  deeply feels the limitations of the present. Who knows its frustrations, and who wonders: does this
                  system truly serve us?
                </p>
              </div>
            </div>
          </div>
          <img className="mobile-freedom" alt="Mobile freedom" src="/img/mobile-freedom-display.png" />
        </div>
        <div className="overlap-2">
          <img className="mobile-title-section" alt="Mobile title section" src="/img/mobile-title-section.svg" />
          <div className="mobile-title">
            <div className="overlap-3">
              <img className="mobile-title-2" alt="Mobile title" src="/img/mobile-title-background.png" />
              <div className="mobile-title-content">
                <div className="group">
                  <p className="the-new-internet">
                    <span className="span">The</span>
                    <span className="text-wrapper-10"> New </span>
                    <span className="span">Internet</span>
                  </p>
                  <div className="frame">
                    <img className="versus-logo" alt="Versus logo" src="/img/versus-logo.png" />
                    <div className="VERSUS">Versus</div>
                  </div>
                </div>
                <p className="text-wrapper-11">The future you invest in will be the one you live</p>
              </div>
            </div>
          </div>
          <img className="mobile-nab-bar" alt="Mobile nab bar" src="/img/mobile-nab-bar-section.svg" />
          <div className="mobile-nav-bar">
            <div className="mobile-nav-options">
              <img className="versus-logo-2" alt="Versus logo" src="/img/versus-logo-1-2.png" onClick={scrollToTop}/>
              <div className="dropdown">
                <img
                  className="navigation-button"
                  alt="Navigation button"
                  src="/img/navigation-button.png"
                  onClick={toggleDropdown}
                />
                <div className={`dropdown-menu ${isDropdownOpen ? 'active' : ''}`} ref={dropdownRef}>
                  <div className="dropdown-item" onClick={() => scrollToSection('about')}>About</div>
                  <div className="dropdown-item" onClick={() => scrollToSection('documents')}>Documents</div>
                  <div className="dropdown-item" onClick={() => scrollToSection('targets')}>Targets</div>
                  <div className="dropdown-item" onClick={scrollToBottom}>Contacts</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
