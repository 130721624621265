import React, { useState, useEffect } from 'react';
import { FaDownload, FaSearchPlus, FaSearchMinus, FaExpandAlt, FaMinus, FaPlus, FaExpand } from 'react-icons/fa';
import './PdfViewer.css';

const PdfViewer = ({ fileArray }) => {
  const [page, setPage] = useState(1);
  const [zoom, setZoom] = useState(100);

  // Helper function to round to the nearest 5
  const roundToNearestFive = (value) => {
    return Math.round(value / 5) * 5;
  };

  // Calculate the correct zoom so the image fits the container perfectly with a 10px margin
  const calculateFitZoom = () => {
    const img = document.getElementById('pdf-image');
    const container = document.getElementsByClassName('pdf-viewer-container')[0];

    if (img && container) {
      const containerWidth = container.clientWidth - 10;  // 10px margin
      const containerHeight = container.clientHeight - 10; // 10px margin
      const imgWidth = img.naturalWidth;
      const imgHeight = img.naturalHeight;

      if (imgWidth && imgHeight) {
        // Calculate zoom ratios for width and height to fit within container with a 10px margin
        const widthRatio = containerWidth / imgWidth;
        const heightRatio = containerHeight / imgHeight;

        // Choose the smaller ratio to ensure the image doesn't overflow in either direction
        const fitRatio = Math.min(widthRatio, heightRatio) * 100; // Convert ratio to percentage for zoom

        return roundToNearestFive(fitRatio); // Round to nearest 5
      }
    }
    return 100; // Default zoom if no image or container is loaded
  };

  // Set zoom only once when the component initially loads
  useEffect(() => {
    const fitZoom = calculateFitZoom();
    setZoom(fitZoom); // Set initial zoom to fit
  }, []); // Empty dependency array ensures it runs only once on load

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= fileArray.length) {
      setPage(newPage);
    }
  };

  const handleZoomChange = (zoomChange) => {
    setZoom((prevZoom) => Math.max(roundToNearestFive(prevZoom + zoomChange), 50)); // Prevent zooming out below 50%, rounding to nearest 5
  };

  const handleDownload = () => {
    window.open(fileArray[page - 1], '_blank');
  };

  const toggleFullscreen = () => {
    const doc = document.documentElement;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      doc.requestFullscreen();
    }
  };

  const fitToScreen = () => {
    const fitZoom = calculateFitZoom();
    setZoom(fitZoom); // Set zoom to the calculated fit zoom
  };

  return (
    <div className="pdf-viewer-wrapper">
      {/* Toolbar for PDF controls */}
      <div className="toolbar">
        {/* Versus Logo */}
        <a href="/" className="logo">
          <img src={`${process.env.PUBLIC_URL}/img/versus-logo.png`} alt="Versus Logo" height="30px" />
        </a>

        {/* Controls Group */}
        <div className="controls-group">
          {/* Page controls */}
          <div className="page-controls">
            <button onClick={() => handlePageChange(page - 1)}><FaMinus style={{ color: 'white' }}/></button>
            <input
              type="number"
              min="1"
              max={fileArray.length}
              value={page}
              onChange={(e) => handlePageChange(Number(e.target.value))}
            />
            <button onClick={() => handlePageChange(page + 1)}><FaPlus style={{ color: 'white' }}/></button>
          </div>

          {/* Zoom controls */}
          <div className="zoom-controls">
            <button onClick={() => handleZoomChange(-10)}><FaSearchMinus style={{ color: 'white' }}/></button>
            <input
              type="number"
              min="50"
              max="400"
              value={zoom}
              onChange={(e) => setZoom(Number(roundToNearestFive(e.target.value)))}
            />
            <button onClick={() => handleZoomChange(10)}><FaSearchPlus style={{ color: 'white' }}/></button>
          </div>

          {/* Fit view */}
          <button onClick={fitToScreen}><FaExpandAlt style={{ color: 'white' }}/></button>

          {/* Fullscreen */}
          <button onClick={toggleFullscreen}>
            <FaExpand style={{ color: 'white' }}/>
          </button>
        </div>

        {/* Action Controls */}
        <div className="action-controls">
          <button onClick={handleDownload}><FaDownload style={{ color: 'white' }}/></button>
        </div>
      </div>

      {/* PDF Viewer container */}
      <div className="pdf-viewer-container" style={{ overflow: 'auto', backgroundColor: 'grey' }}>
        <div
          className="pdf-page"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          <img
            id="pdf-image"
            src={fileArray[page - 1]} 
            alt={`Page ${page}`}
            style={{
              width: `${zoom}%`,
              height: 'auto',
              maxWidth: '100%',
              objectFit: 'contain'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
