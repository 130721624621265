import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { Mobile } from "./Mobile";
import { Desktop } from "./Desktop";

export const VersusWebsiteFor = () => {
  const screenWidth = useWindowWidth();

  return (
    <>
      {screenWidth < 1440 ? <Mobile /> : <Desktop />}
    </>
  );
};